@import '../../styles/variables.scss';


@mixin text-base{
    font-size: 20px;
    line-height: 1.45em;
    font-family: Work Sans;
    color: white;
    @media (max-width: $md){
        font-size: 17px;
    }
}

.players-page{
    .hide-mobile{
        @media (max-width: $sm) {
            display: none;
        }
    }
    .show-mobile{
        @media (min-width: $sm) {
           display: none;
        }
    }
    section{
        background-color: $orange;
        .container {
            max-width: 1630px;
        }
    }
    .carousel-mobile{
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
        .slick-dots{

            li{
                width: 25px;
                list-style: none;
                button{
                    background: rgba(white, 0.6);
                    border-radius: 15px;
                    width: 15px;
                    height: 15px;
                    &:before{
                        display: none;
                    }
                }
                &.slick-active{
                    width: 40px;
                    button{
                        background-color: white;
                        width: 30px;
                    }
                }
            }
        }
        img{
            width: auto;
            height: 250px;
            margin: 0 auto 20px;
        }
    }
}


#intro_players{
    background-position: bottom center;
    background-size: 130% 80%;
    background-repeat: no-repeat;
    padding-top: 100px;
    min-height: 600px;
    @media (max-width: $sm){
        background-size: 700% 80%;
    }
    #title_players{
        text-align: center;
        height: 100vh;
        
        display: flex;
        margin-bottom: -150px;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: right;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        #button_players{
            margin-top: 30px;
            max-width: 222px;
            @media (max-width:$sm) {
                max-width: 150px;
            }
        }
        #muffin_players_head{
            height: 80vh;
            top: 20vh;
            left: -100px;
            position: absolute;
            @media (max-width:$sm){
                height: 38vh;
                top: 67vh;
                left: -75px;
                position: absolute;
                opacity: 0.5;
            }
        }
        #title_player_text{
            z-index: 10;
            
            border-radius: 20px;
            h1{
                background-color: rgba($orange, 0.4);
                    box-shadow: 0px 0px 34px 23px rgba(244, 157, 0, 0.4);
            }
            
        }
        #muffin_bocca{
            height: 100vh;
            top: 10vh;
            right: 10px;
            position: absolute;
            z-index: 1;
            @media (max-width:$lg){
                height: 55vh;
                
            }
            @media (max-width:$md){
                height: 45vh;
            }
            @media (max-width:$sm){
                height: 34vh;
                right: 0px;
                position: absolute;
            }
        }
        
        @media (max-width: $sm){
            padding: 50px 0;
            background-image: none!important;
            margin-bottom: 0px;
           
            #bocca_mobile{
                display: block;
                width: 100%;
            }
        }
        h1 {
            @include outlinefont;
            font-size: 160px;
            line-height: 1em;
            color: white;
            @media (max-width: $sm) {
                font-size: 60px;
            }
        }
        h3{
            color: $blue;
            font-size: 29px;
            line-height: 1.1em;
            @media (max-width: $sm){
                font-size: 23px;
            }
        }
    }
}
#upgrade-your-skills-wrap{
    background-color: $orange;
    padding-bottom: 0px;
    @media (max-width: $md) {
        padding-bottom: 0px;
    }
    #upgrade-your-skills{
        
        display: table;
        padding-bottom: 0px;
        padding-top: 250px;
        @media (max-width:$md){
            padding-bottom: 0;
            padding-top: 0;
        }
        #upgrade-your-skills-logo{
            width: 100%;
            img{
                width: 100%;
                max-width: 555px;
                @media (max-width: $sm) {
                        max-width: 350px;
                        margin: 0 auto;
                    }
            }
        }
        #upgrade-your-skills-row{
            @media (min-width: $sm) {
                display: flex;
            }
            #upgrade-your-skills-left{
                @media (min-width: $sm) {
                    width: 50%;
                }
                display: flex;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding: 3% 3% 2% 3%;
            @media (max-width: $sm) {
                padding: 6% 6% 2%;
            }
                video{
                    width: 100%;
                }
            }
            #upgrade-your-skills-right {
                padding: 0 30px;
                @media (min-width: $sm) {
                    width: 50%;
                    display: table;
                }
                .upgrade-your-skills-box{
                    width: 100%;
                    padding: 40px 20px;
                    font-size: 18px;
                    text-align: center;
                    @include text-base();
                    img{
                        max-width: 100%;
                    }
                    @media (max-width: 1400px){
                        font-size: 16px;
                    }
                    @media (min-width: $sm){
                        float: left;
                    }
                    color: white;
                    
                    img{
                        
                        max-width: 100%;
                        height: auto;
                        width: 100%;
                        @media (max-width: $md) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
#onda-creators{
    width: 100%;
    height: 1000px;
    margin: -764px 0 -300px;
    left: 0;
    background-size: 2500px auto;
    background-repeat: no-repeat;
    background-position: top;
    @media (min-width: 2500px) {
        background-size: 100% 850px;
    }
    @media (max-width: $sm){
        height: 500px;
            margin: -250px 0 -100px;
        background-size: 150% auto;
    }
}
#mr-day-creators{
    padding-top: 150px;
    padding-bottom: 150px;
    background-color: $blue;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 105% auto;
    @media (max-width: $md) {
        margin-top: 0px;
        padding-top: 100px;
        background-position-y: 250px;
    }
    @media (max-width:$sm){
        padding-top: 0;
        background-position-y: 454px;
    }
    .container{
        padding-top: 20px;
    }
}

#la-pausa-che-non-lagga-wrap{
    background-color: $blue;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;
    padding-bottom: 150px;
    @media (min-width: 2000px){
        
                background-size: 130% auto;
            
    }
    #la-pausa-che-non-lagga-row {
        @media (min-width: $sm) {
            display: flex;
            flex-wrap: wrap;
        }
    #la-pausa-che-non-lagga-left {
        display: flex;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 3% 3% 2% 3%;
        @media (min-width: $sm) {
            width: 40%;
        }
        @media (max-width: $sm) {
            padding: 6% 6% 2%;
        }
        video {
            width: 100%;
        }
    }
    #la-pausa-che-non-lagga-right {
        padding: 0 30px;

        @media (min-width: $sm) {
            width: 60%;
            display: flex;
            flex-wrap: wrap;
        }

        .lagga-box-text {
            img{
                display: none;
            }
            width: 50%;
            padding: 40px 20px;
            font-size: 18px;
            text-align: left;
            
            @include text-base();
            @media (max-width: 1400px) {
                font-size: 16px;
            }
            @media (max-width: $md) {
                width: 100%;
                img{
                    display: block;
                    max-width: 100%;;
                }
            }
            @media (min-width: $sm) {
                float: left;
            }
        }
        .lagga-box-logo {
            width: 50%;
            padding: 5px;
            img{
                width: 100%;
            }
            @media (max-width: $md) {
                display: none;
            }
        }
        .lagga-box-img {
            @media (max-width:$sm){
                display: none;
            }
            width: 33%;
            padding: 5px;
            img{
                width: 100%;
            }
        }
    }
}}
#mr-day-creators{
    .creator_list{
        
        @media (min-width: $sm){
            display: flex;
            flex-wrap: wrap;
            .creator_box{
                width: 25%;
                img{
                    max-width: 100%;
                }
            }
        }
    }
}
#secret-match{
    font-family: Work Sans;
    background-size: 35% auto;
    background-position: top right;
    background-repeat: no-repeat;
    color: white;
    font-size: 20px;
    line-height: 32px;
    padding-top: 70px;
    @media (min-width: 2500px) {
            background-size: auto 100%;
            background-position: center;
            background-position-x: calc(50% + 650px);
        }
    @media (max-width: $sm) {
        background-size: 100% auto;
        margin-top: -1px;
    }
    .container{
        display: flex;
    }
    #secretmatch-video{
        width: 100%;
    }
    #secretmatch-video-wrap{ 
        max-width: 500px;
        width: 100%;
        padding: 14% 18% 18% 15%;
        background-size: contain;
        background-position: left;
        background-repeat: no-repeat;
        transform: rotate(-11deg);
        @media (max-width: $sm) {
                padding: 15% 15% 16%;
            }
        video{
            margin: 0 5%;
            width: 90%;
            
        }
    }
    #secret-match-left{
        width: 66%;
        display: flex;
        flex-wrap: wrap;
        @media (max-width: $sm) {
            width: 100%;
            display: none;
        }
        .secret-match-text{
            width: 66%;
            align-items: flex-end;
            display: flex;
            padding: 30px;
            @include text-base();
        }
        .secret-match-baloon,         
        .secret-match-block{
            padding: 20px;
            width: 33%;
            img{
                max-width: 100%;
            }
        }
    }
   
    #secret-match-right{
        display: flex;
        flex-wrap: wrap;
        width:33%;
        @media (max-width: $sm) {
                width: 100%;
            }
    }
    #secret-match-img,
    #secret-match-text{
        width: 50%;
        @media (max-width: $sm){
            width: 100%;
            img{
                max-width: 80%;
                margin: 10px auto;
            }
        }
    }
        #secret-match-text{
            padding: 50px 20px 20px;
            
        }
}

 #secret-match-text-mobile {
    @media(min-width: $sm){
        display: none;;
    }
     padding: 0 30px 50px;
     img{
        display: block;
        width: 250px;
        margin: 0 auto 20px;
     }
     @include text-base();
 }

#vinci-un-giorno-da-mates{
    padding: 250px 0 150px;
    font-family: Work Sans;
    background-position: center;
    background-size: 150% 100%;
    .container{
        display: flex;
        flex-wrap: wrap;
        .mates-left {
            width: 19%;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            @media (max-width:$sm) {
                width: 100%;
                flex-direction: row;
                align-items: center;
                img{
                    object-fit: contain;
                    width: 150px;
                    height: 150px;
                }
            }
        }
        #mates-video {
            width: 30%;
            background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            
                @media (max-width:$sm) {
                    background-size: 125% auto;
                    margin: 0 -5%;
                    width: 100%;
                }
            
                #mates-video-wrap {
                    background-size: contain;
                    padding: 10%;
                    max-width: 530px;
                    margin: 0 auto;
                    background-repeat: no-repeat;
                    background-position: center;
            
                    @media (max-width: $sm) {
                        padding: 20%;
                    }
            
                    video {
                        width: 100%;
                    }
                }
        }    
        .mates-right {
            width: 50%;
            display: flex;
            flex-wrap:wrap;
            @media (max-width:$sm) {
                    width: 100%;
                }
            #mates-logo{
                width: 33%;
                padding: 10px;
                text-align: left;
                font-size: 20px;
                line-height: 25px;
                @media (max-width: $md) {
                        width: 100%;
                        img{
                            max-height: 150px;
                            margin: 0 auto;
                            display: block;
                        }
                    }
            }
            .mates-img-block{
                width: 50%;
                padding: 10px;
            }
            #mates-text{
                width: 66%;
                padding: 10px;
                text-align: left;
                @include text-base();
                @media (max-width: $md) {
                        width: 100%;
                    }
            }
        }
    }

    @media (max-width: $sm) {
        padding-top: 180px;
        background-size: 600% 100%;
    }
    img{
        max-width: 100%;
    }

   
    #mates-text,
    #mates-img{
        margin: 0px auto;
        max-width: 674px;
        display: flex;
        align-items: center;
        @media (max-width: $sm){
            display: table;
            
        }
    }
    #mates-text{
        padding-top: 30px;
        text-align: center;
        color: white;
    }
    #mates-img {
        img{
            height: auto;
            max-width: 50%;
            @media (max-width: $sm) {
                max-width: 80%;
                margin: 0 auto;
                display: block;
            }
        }
    }
}

#backto{
    background-color: white;
    text-align: center;
    padding-bottom: 100px;
    a{
        color:$blue;
        font-size: 28px;
        text-decoration: none;
    }
    img{
        display: block;
        margin: 0 auto 15px;
        max-width: 300px;
        @media (max-width: $sm){
            max-width: 100px;
        }
    }
}
#player-slider{
    background-color: $orange;
    padding: 50px 0;
}

#live-su-twitch{
    @media (min-width: $sm) {
        width: 500px;
        position: absolute;
    }
    img{
        width: 100%;
    }
}

