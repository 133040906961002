@import '../../styles/variables.scss';

.app {
  overflow: hidden;

  .header_home {
    overflow-x: hidden;
    background-color: $orange;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 0px;
    .head_container {
      background-color:#083BB3;
      width: 100%;
        img {
          width: 100%;
          @media (min-width: 1500px){
            min-height: 1080px;
          }
          @media (min-width: $sm){
            object-fit: cover;
             // max-height: 150vh;
              width: 100%;
              object-position: center bottom;
          }
        }
      }
    @media screen and (max-width: $sm) {
      padding: 0;
    }

    .logo {
      position: absolute;
      top: 10px;
      z-index: 5;
    }

    .video_container {
      width: 100%;
      height: 100%;
      flex: 2;
      z-index: 2;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      iframe {
        height: 100%;
        width: 100%;
      }  
    }

    .start_background {
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
      height: 105vh;
      z-index: 1;
      display: flex;
      justify-content: center;

      img {
        height: 100%;
      }
    }
  }

  .level_up {
    color: white;
    padding: calc(5vh + 40px) 30px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: 800px;
    overflow: hidden;
    position: relative;
    background-color: $orange;

    .content {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 25px;

      .left {

        h2 {
          font-size: 83px;
          line-height: 1;
        }

        button {
          font-family: 'Bungee', cursive;
          border: 0;
          color: white;
          background-color: $blue;
          padding: 30px 40px;
          border-radius: 100px;
          margin-top: 30px;
          font-size: 18px;
          cursor: pointer;
        }

      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-self: flex-end;
        max-width: 300px;
        z-index: 2;

        transition: 1.5s;
        opacity: 0;
        transform: translateX(50vw);

        &.active {
          opacity: 1;
          transform: translateX(0) !important;
        }

        p {
          font-family: 'Work Sans', sans-serif;
          text-align: right;
          font-size: 20px;

          b {
            letter-spacing: 0.8px;
          }
        }
      }      
    }

    @media screen and (max-width: $sm) {
      
      height: auto;

      .content {
        display: flex;
        flex-direction: column;

        .left {
          h2 {
            font-size: 64px;
          }
        }
  
        .right {
          margin-top: 50px;
          width: 80%;
          align-self: flex-start;
          transform: translateX(100vw);

          p {
            text-align: left;
          }
        }
      }
    }

    @media screen and (max-width: $xs) {
      padding-bottom: 200px;
    }

  }

  .eye_mid_section {
    background-color: $blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    overflow-x: hidden;
    
    .orange_bottom {
      margin-top: -2px;
      width: 100vw;
      height: auto;

      #eye {

        &.hide {
          path {
            fill: $blue;
          }
        }
      }
    }

    @media screen and (max-width: $md) {
      .orange_bottom {
        width: 200vw;
      }
    }

    @media screen and (max-width: $xs) {
      .orange_bottom {
        width: 250vw;
      }
    }
  }

  .new_patch {
    background-color: $blue;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 30px;
    background-repeat: no-repeat;
    background-position-x: calc(50% - 400px);
    background-position-y: calc(50% - 100px);

    .content {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 15px;
      max-width: 900px;
      width: 100%;

      .left {
        padding-bottom: 50px;

        h2 {
          font-size: 147px;
          line-height: 1;
        }

      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-self: flex-end;

        p {
          text-align: right;
          line-height: 1.1;
          color: $orange;
          font-size: 34px;

          span {
            white-space: nowrap;
          }
        }
      }      
    }

    @media screen and (max-width: $md) {
      
      background-position-x: -170px;
      background-size: 600px;

      .content {
        padding: 0 80px;

        .left {
          padding-bottom: 10px;

          h2 {
            font-size: 90px;
          }
        }
  
        .right {

          p {
            font-size: 25px;
          }
        }
      }

    }

    @media screen and (max-width: $sm) {
      
      background-position-x: -200px;
      background-size: 500px;

      .content {
        padding: 0;
        display: flex;
        flex-direction: column;

        .left {
          h2 {
            font-size: 70px;
          }
        }
  
        .right {

          p {
            font-size: 20px;

            span {
              white-space: normal;
            }
          }
        }
      }

    }

    @media screen and (max-width: $xs) {

      .content {

        .left {
          h2 {
            font-size: 60px;
          }
        }
      }

    }

  }

  .game {
    background-color: $blue;
    padding: 100px 10px 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      width: calc(100vmin - 20px);
      height: calc(100vmin - 20px);
      max-width: 800px;
      max-height: 800px;
      display: flex;
      align-items: center;
      justify-content: center;

      iframe {
        border: 0;
        width: 100%;
        height: 100%;
      }
  
      .play_button {
        background-color: transparent;
        border: 0;
        width: 300px;
        height: 300px;
        border-radius: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
  
        .nohover {
          transform: translateY(20px);
        }
      }
    }


  }

  .blue_mid_section {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;

    img {
      width: 100vw;
      height: auto;
      margin-top: -2px;
    }
  }

  .skin {
    padding: 60px 0 120px;


    h2 {
      padding: 0 30px;
      color: $blue;
      line-height: 1;
      font-size: 83px;
      text-align: center;
      margin-bottom: 80px;
    }


  }

}

#ombra_1{
  path{
  }
}

