@import '../../styles/variables.scss';

header{
    width: 100%;
    padding: 10px;
    position: absolute;
    z-index: 50;
    display: flex;
    justify-content: center;
    img{
        display: block;
        margin: 0px auto;
        z-index: 12;
        max-width: 100%;;
    }
        .hamburger-react {
            position: absolute!important;
            right: 20px;
            top: 15px;
            z-index: 10;
            div{
                transition: 0.5s;
                background-color: white!important;
            }
            &[aria-expanded=true]{
                position: fixed!important;
                    top: 15px;
                div{
                    background-color: $blue!important;
        
                }
            }
        }
}

#main_menu{
    transition: height 0.5s;
    height: 0px;
    overflow: hidden;
    background-color: rgba(white, 0.9);
    position: absolute;
    justify-content: center;
    top:0;
    right: 0;
    width: 100%;
    max-width: 580px;
    display: flex;
    align-items: center;
    z-index: 1;
    ul{
        padding: 0 10%;
        list-style-type: none;
        li{
            padding: 15px 0;
            a{
                color: $orange;
                font-size: 90px;
                text-decoration: none;
                line-height: 0.9em;
                padding: 0.1em 0;
                display: block;
                @media (max-width: $sm){
                    font-size: 60px;
                }
            }
            &:not(:first-child){
                border-top: 1px solid $blue;
            }
        }
    }
    &.open{
        position: fixed;
        top: 0;
        height: 100vh;
    }
}