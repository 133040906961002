@import '../../styles/variables.scss';

.cookie_policy {
  color: $blue;
  display: flex;
  flex-direction: column;
  .container{
    padding-top: 100px;
  }
  h1 {
   
    text-align: center;
    font-size: 45px;
    padding: 40px 20px 0;
    line-height: 1.1;
  }
  h2, h3, h4{
    margin: .5em 0 1em;
  }
  ol, ul, p{
      font-family: "Work Sans", sans-serif;
      margin-bottom: .65em;
      line-height: 1.4em;
  }
  #cookie-content {
    font-family: 'Work Sans', sans-serif;
    p:first-child{
      display: none;;
    }
    .CookieDeclarationType {
      overflow-x: auto;
      border: 5px solid $orange;
      border-radius: 15px;

      .CookieDeclarationTypeHeader {
        font-family: 'Bungee', cursive;
      }

      .CookieDeclarationTypeHeader,
      .CookieDeclarationTypeDescription {
        position: sticky;
        left: 0;
      }

      .CookieDeclarationTable {
        min-width: 600px;
      }
    }

  }
}

.container{
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}
