@import '../../styles/variables.scss';

@mixin product-colors($background, $text, $title, $th){
    .toptable{
        background-color: $background;
    }
    #intro_product{
        background-color: $background;
        h1,h2{
            color: $title
        }
    }
    #nutritional_facts{
        background-color: $text;
        th{
            background-color: $th;
            border: 5px solid $text;
        }
        tr{
            td{
                &:first-child {
                        border-left: 15px solid $text;
                    }
                
                    &:last-child {
                        border-right: 15px solid $text;
                    }
            }
        }
    }
}
.product-page{
    .logo{
        margin-top: -10px;
    }
}
#intro_product{
    background-position: top right;
    background-size: 52% auto;
    min-height: 100vh;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5vh;
    @media (max-width: $xs) {
        background-size: auto 321px;
        background-position: right 111px;

    }
}
.product-page{
    .small-notes{
        margin-top: 2em;
        font-size: 0.8em;
        font-style: italic;
    }
    #intro_product{
        color: white;
        #product_info{
            padding-top: 70vh;
            max-width: 800px;
            @media (max-width: $xs) {
                padding-top: 350px;
            }
        }
        h1,h3{
            max-width: 550px;
        }
        h1,h2,h3{
            max-width: 700px;
        }
        h1{
            font-size: 93px;
            line-height: 85px;
            margin-bottom: 15px;
            @media (max-width: $xs) {
                font-size: 46px;
                line-height: 50px;
                margin-top: 100px;
            }
        }
        h2{
            font-family: Bungee, cursive;
            font-size: 32px;
            @media (max-width: $xs) {
                font-size: 22px;
            }
        }
        h3{
            margin-top: 110px;
            margin-bottom: 25px;
            font-size: 50px;
            line-height: 50px;
            .mobile{
                display: none;
            }
            @media (max-width: $xs) {
                    font-size: 30px;
                    line-height: 35px;
                    margin-top: 30px;
                    .mobile {
                            display: inline;
                        }
                    .desktop {
                            display: none;
                        }
                }
        }
        p{
            font-family: Work Sans;
            font-size: 20px;
            line-height: 1.5em;
            margin-bottom: 1.1em;
            @media (max-width:$xs){
                font-size: 16px;
            }
        }
    }
    &.brownies-al-cioccolato{
        @include product-colors($brown,$orange, $orange, $blue);
    }
    &.muffin-proteico-caramello-salato{
        @include product-colors(black, $green, $green, $blue);
    }
    &.muffin-senza-glutine-cioccolato-fondente{
        @include product-colors($orange, $green, $blue, $blue);
    }
    &.plumcake-goccia-di-miele,
    &.muffin-super-pepite-cioccolato-fondente,
    &.muffin-super-al-cacao{
        @include product-colors($blue, $orange, $orange, $blue);
    }
    &.ciambella-vaniglia,
    &.ciambella-crema-al-cioccolato {
        @include product-colors($orange,$blue, $blue, $orange);
    }
}
#infopack{
    max-width: 800px;
    margin: 0 auto;
    font-family: "Work Sans", sans-serif;
    font-size: 1.2em;
    color: white;
    line-height: 1.5em;
}
#nutritional_facts{
    padding: 30px 0;
    .container{
        overflow: auto;
    }
    table {
        color: white;
        border: 2px solid white;
        border-radius: 40px;
        font-family: Work sans;
        margin: 0 auto;
        th {
            border-spacing: 0;
            margin: 0;
            padding: 20px;
            text-align: center;
            min-width: 150px;
            @media (max-width: $sm) {
                padding: 13px;
                min-width: 140px;
                font-size: 16px;
            }
            &:first-child{
                border-top-left-radius:  40px;
            }
            &:last-child{
                border-top-right-radius:  40px;
            }
        }
    
            tr {
                td{
                    padding: 0 10px;
                    font-size: 20px;
                    line-height: 42px;
                    @media (max-width: $sm){
                        font-size: 16px;
                    }
                }
                &:nth-child(n + 3){
                    td{
                        border-top: 1px solid white;
                    }
                }
                td{
                    &:not(:last-child) {
                        border-right: 1px solid white;
                    }
                    
                }
                &:last-child{
                    td{
                        &:first-child{
                            border-bottom-left-radius: 40px;
                        }
                        &:last-child{
                            border-bottom-right-radius: 40px;
                        }
                    }
                }
            }
        }
}

.toptable{
    margin-bottom: -10px;
    padding-top: 5vh;
    .eye{
        position: absolute;
        margin-top: -8%;
        @media (max-width: $xs){
            display: none;
        }
    }
}

.bottomtable{
    overflow: hidden;
    img{
        @media (max-width: $xs) {
            width: 200%;
        }
    }
}

.ScrollbarsCustom-ThumbX{
        background: $blue!important;
    
} 