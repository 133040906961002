@import '../../styles/variables.scss';


footer {
  background-repeat: no-repeat;
  background-size: 1300px auto;
  background-position-x: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 20px 130px;
  background-image: url('../../../public/img/footer_throat.svg');
  @media screen and (max-width: $md) {
    background-image: url('../../../public/img/footer_throat_mobile.svg');
  }
  .social_container {
    display: flex;
    width: 100%;
    justify-content: center;
    max-width: 1300px;
    margin-bottom: 20px;

    .social_link {
      margin: 0 20px;
      height: 45px;
      width: auto;

      img {
        height: 45px;
        width: auto;
      }
    }
  }
  .copy {
    font-family: 'Work Sans', sans-serif;
    text-align: center;
    color: white;
    max-width: 1000px;
  }
  
  .cookie_link {
    font-size: 14px;
    text-decoration: underline;
    color: white;

    &:hover {
      color: #efba5a;
    }
  }

  @media screen and (max-width: $md) {
    background-size: 900px auto;
    padding: 130px 20px 65px;

    .social_container {
      margin-bottom: 10px;

      .social_link {
        img {
          height: 35px;
          width: auto;
        }
      }
    } 

    .copy {
      max-width: 700px;
    }

  }

  @media screen and (max-width: $xs) {
    background-size: 1150px auto;
    padding: 200px 20px 65px;
    background-size: auto 150%;
    .social_container {
      margin-bottom: 10px;

      .social_link {
        img {
          height: 35px;
          width: auto;
        }
      }
    } 

    .copy {
      max-width: 700px;
    }

  }
}

.footer_link{
  display: flex;
  @media (max-width: $sm){
    flex-direction: column;
    padding-bottom: 15px;
  }
  a{
    color: white;
    margin: 5px;
  }
  
}