@import '../../styles/variables.scss';
 .skin {
    background-color: white;
    padding: 60px 0 120px;
    h2 {
        padding: 0 30px;
        color: $blue;
        line-height: 1;
        font-size: 83px;
        text-align: center;
        margin-bottom: 80px;
        @include outlinefont;
        color: #013BB2;
        @media (max-width: $xs) {
            font-size: 60px;
            margin-bottom: 30px;
        }
    }
    .carousel-slider {
        .carousel-arrow {
            position: absolute;
            height: 80px;
            width: 80px;
            background-color: transparent;
            border: 0;
            cursor: pointer;
            bottom: 0;
            &.right {
                transform: translate(100px, 80px);
                left: 50%;
                &:hover {
                    img {
                        transform: rotate(-25deg);
                    }
                }
            }
            &.left {
                transform: translate(-100px, 80px);
                right: 50%;
                &:hover {
                    img {
                        transform: rotate(25deg);
                    }
                }
            }
            @media screen and (max-width: 350px) {
                &.right {
                    transform: translate(50px, 80px);
                }
                &.left {
                    transform: translate(-50px, 80px);
                }
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        .slick-list {
            .slick-slide {
                transition: .5s;
                &:not(.slick-current) {
                    .pack-detail {
                        visibility: hidden;
                    }
                }
                &.slick-current {
                    transform: translateY(50px);
                    z-index: 10;
                    .light {
                        opacity: 1 !important;
                    }
                }
                &:not(.slick-active) {
                    transform: translateY(-50px);
                    img {
                        filter: brightness(50%);
                    }
                }
                &:not(.slick-current){
                    img{
                        filter: brightness(75%);
                    }
                }
                div {
                    .item-content {
                        display: flex !important;
                        flex-direction: column;
                        align-items: center;
                        .pack-detail-container {
                            width: 300px;
                            height: 220px;
                            display: flex;
                            align-items: flex-end;
                            .pack-detail {
                                display: flex;
                                align-items: center;
                                width: 300px;
                                padding: 40px 20px 30px;
                                background-repeat: no-repeat;
                                background-size: 100% 100%;
                                img {
                                    width: 120px;
                                    height: auto;
                                }
                                p {
                                    line-height: 1;
                                    color: $orange;
                                    margin-left: 10px;
                                }
                                a{
                                    background-color: $blue;
                                    color: white;
                                    border-radius: 90px;
                                    padding: 20px;
                                    margin-top: 10px;
                                    text-decoration: none;
                                    display: block;
                                    font-size: 0.9em;
                                }
                            }
                        }
                        .item-image-container {
                            width: 170px;
                            height: 170px;
                            transform: translateY(-35px);
                            .item-image {
                                width: 170px;
                                height: auto;
                            }
                        }
                        .pod {
                            width: 200px;
                            height: auto;
                            transform: translateY(-95px);
                            .light {
                                opacity: 0;
                                transition: 1s;
                            }
                        }
                    }
                }
            }
        }
    }
}