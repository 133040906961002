@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Bungee+Outline&family=Work+Sans:ital@0;1&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}
body {
  overflow-x: hidden;
  font-family: 'Bungee', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-family: 'Bungee Outline', cursive;
  font-weight: 400;
}
