@import '../../styles/variables.scss';


.mouth {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  transform: translate(100px, 50px) scale(1.5) rotate(8deg);

  .top {
    transform: translateY(70px);
    z-index: 3;
  }

  .inner {
    background-color: #e3501e;
    width: 240px;
    height: 0;
    transform: translate(3px, 25px);
    transition: 1s;

    &.active {
      height: 200px
    }
  }

  .bottom {
  }

  @media screen and (max-width: $sm) {
    transform: translate(115px, 150px) rotate(8deg);

    .inner {
  
      &.active {
        height: 300px
      }
    }
  }

  @media screen and (max-width: $xs) {
    transform: translate(115px, 180px) rotate(8deg);
  }
}