$blue:      #013BB2;
$orange:    #F49D00;
$green:     #62B432;
$brown:       #411D15;

$xs: 450px;
$sm: 768px;
$md: 991px;
$lg: 1200px;


@mixin outlinefont{
    font-family: 'Bungee Outline', cursive;
    font-weight: 400;
}